<template>
    <div>
        <el-dialog :title="!form.deptId ? $t('button.add'): $t('button.update')" v-model="visible" append-to-body width="400px" destroy-on-close>
            <el-form :model="form" :rules="rules" ref="from_"  label-position="top">
                <platformCheck @setTenantId='setTenantId_'/>
                <el-form-item prop="parentId" :label="$t('deptFrom.text1')">
                    <el-tree-select v-model="form.parentId" :data="dataList" check-strictly
                    />
                </el-form-item>
                <el-form-item prop="name" :label="$t('deptFrom.text2')">
                    <el-input v-model="form.name" :placeholder="$t('button.update')"/>
                </el-form-item>
                <el-form-item prop="sortOrder" :label="$t('deptFrom.text3')">
                    <el-input v-model="form.sortOrder" type="number" :placeholder="$t('button.update')"/>
                </el-form-item>
            </el-form>
                <template #footer>
                    <div class="dialog-footer">
                        <el-button @click="visible = false">{{$t('button.close')}}</el-button>
                        <el-button type="primary" @click="submitFun(from_)">{{$t('button.tijiao')}}</el-button>
                    </div>
                </template>
        </el-dialog>
    </div>
</template>

<script setup>
    import Api_ from '@/api/index'
    import { ref,reactive,defineExpose,defineEmits,getCurrentInstance } from 'vue'
    import { ElNotification } from 'element-plus' 
    const { $t } = getCurrentInstance().proxy;
    import platformCheck from '@/components/platformCheck.vue'

    const emit = defineEmits(['refreshDataList'])
    const dataList = ref([])
    const visible = ref(false)
    const form = reactive({
        name: undefined,
        sortOrder: 999,
        parentId: 0,
        deptId: undefined,
        tenantId:''
    })
    const from_ = ref(null)
    
    const setTenantId_ = (e)=>{
        form.tenantId = e
    }
    const validateRoleCode = (rule, value, callback)=>{
        Api_.deptList({deptName:value}).then(res=>{
            if (form.deptId) {
                if (res && res.data.length !== 0 && res.data[0].deptId !== form.deptId) {
                    callback(new Error($t('deptFrom.rules1')))
                } else {
                    callback()
                }
            }else{
                if (res && res.data.length !== 0) {
                    callback(new Error($t('deptFrom.rules1')))
                } else {
                    callback()
                }
            }
        })
    }
    const rules = ref({
        parentId: [{ required: true, message: $t('inputTit.xuanze'), trigger: 'blur' }],
        name: [{ required: true, message: $t('inputTit.xuanze'), trigger: 'blur' },
            { validator: validateRoleCode, trigger: 'blur' }],
        sortOrder: [{ required: true, message: $t('inputTit.xuanze'), trigger: 'blur' }],
    })

    const init_ = (isEdit, id)=>{
        if (id !== null) {
            form.parentId = id;
        }
        visible.value = true
        if (isEdit) {
            Api_.getdeptObj(id).then(response => {
                form.name = response.data.name
                form.sortOrder = response.data.sortOrder
                form.parentId = response.data.parentId
                form.deptId = response.data.deptId
                form.tenantId = response.data.tenantId
            })
        } else {
            form.deptId = undefined
            form.name = ''
            form.parentId = ''
            form.deptId = ''
        }
    }
    const submitFun = (form_)=>{
        if (!form_) return
        form_.validate((e) => {
            if(e){
                if (form.parentId === undefined) {
                    form.parentId = '0'
                }
                if (form.deptId) {
                    Api_.updateDeptObj(form).then(() => {
                            ElNotification({
                            message: $t('alert.succUpdate'),
                            type: 'success',
                        })
                        visible.value = false
                        emit('refreshDataList')
                    });
                } else {
                    Api_.addDeptObj(form).then(() => {
                            ElNotification({
                            message: $t('alert.succAdd'),
                            type: 'success',
                        })
                        visible.value = false
                        emit('refreshDataList')
                    })
                }
            }
        })
    }
    /** 查询菜单下拉树结构 */
    const getTreeselect = () => {
        Api_.deptListTree().then(response => {
            let array_ = {id: '0', name: $t('deptFrom.rules2'), children: response.data};
            dataList.value = [getDatalist(array_)]
        });
    }
    const getDatalist = (obj_)=>{
        let obj_1 = {}
        for(let index in obj_){
            if(index === 'id'){
                obj_1.value = obj_[index]
            }
            if(index === 'name'){
                obj_1.label = obj_[index]
            }
            if(index === 'children'){
                obj_1.children = getDatalistArray(obj_[index])
            }
        }
        return obj_1
    }
    const getDatalistArray = (st_)=>{
        let array_ = []
        for(let i = 0;i < st_.length;++ i){
            array_[i] = getDatalist(st_[i])
        }
        return array_
    }
    getTreeselect();
    defineExpose({init_})
</script>
<style lang="scss" scoped>
</style>
